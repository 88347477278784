<template>
  <div ref="tocContainerRef" class="pt-10 pb-20 toc-container">
    <div
      class="rounded-lg pa-12 pb-10 toc-container--text-box"
      :style="tocContainerStyle"
    >
      <nav>
        <ul class="ml-0">
          <li
            v-for="link in props.tocLinks"
            :key="link.id"
            class="cursor-pointer text-sm ml-0 mb-2 py-2 position-relative pr-2"
            @click="onClick(link.id)"
          >
            {{ t(`tocSlugs.${page}.${link.id}`) }}
            <span v-if="link.id === props.activeTocId" class="highlight"></span>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useDisplay } from "vuetify";

interface TocLink {
  id: string;
}

const props = defineProps<{
  activeTocId: string;
  tocLinks: TocLink[];
  page: string;
}>();

const router = useRouter();
const { t } = useI18n();
const { width } = useDisplay();

const tocContainerRef = ref<HTMLElement | null>(null);
let observer: IntersectionObserver | null = null;

const positions = reactive({
  tocBottom: 0,
  footerTop: 0,
});

const isTocVisible = computed(() => positions.tocBottom <= positions.footerTop);
const isWidthAtLeast1900 = computed(() => width.value >= 1900);

const tocContainerStyle = computed(() => ({
  opacity: isTocVisible.value ? 1 : 0,
  pointerEvents: isTocVisible.value ? "auto" : "none",
  transition: "opacity 0.3s ease",
}));

const onClick = (id: string) => {
  const el = document.getElementById(id);
  if (el) {
    router.push({ hash: `#${id}` });
    el.scrollIntoView({ behavior: "smooth" });
  }
};

const updatePositions = () => {
  if (tocContainerRef.value) {
    const tocRect = tocContainerRef.value.getBoundingClientRect();
    const footer = document.querySelector("footer");
    if (footer) {
      const footerRect = footer.getBoundingClientRect();
      positions.tocBottom = tocRect.bottom;
      positions.footerTop = footerRect.top;
    }
  }
};

const setupObserver = () => {
  const footer = document.querySelector("footer");
  if (footer && !observer) {
    observer = new IntersectionObserver(
      () => {
        updatePositions();
      },
      {
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      },
    );
    observer.observe(footer);
  }
};

const cleanupObserver = () => {
  if (observer) {
    observer.disconnect();
    observer = null;
  }
};

onMounted(() => {
  if (isWidthAtLeast1900.value) {
    setupObserver();
    updatePositions();
  }
});

watch(isWidthAtLeast1900, (newValue) => {
  if (newValue) {
    setupObserver();
    updatePositions();
  } else {
    cleanupObserver();
  }
});

onUnmounted(() => {
  cleanupObserver();
});
</script>

<style scoped>
.highlight {
  display: inline-block;
  position: absolute;
  right: -25px;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: rgb(var(--v-theme-primary));
}

ul {
  padding-left: 0;
}

li {
  list-style: none;
}
</style>
